export const getInitials = (username: string) => {
  // Remove emoji
  username = username.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')
  const parts = username
    .split(/[ -]/)
    .filter(Boolean)
    .map((part) => part.charAt(0).toUpperCase())
  let initials = parts[0]

  // Get first and last letter only
  if (parts.length > 1) {
    initials += parts[parts.length - 1]
  }

  return initials
}
